@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181,255,0.4);
  --color-white: #fff;
  --color-light: rgba(255,255,255,0.6);
  --color-gray: rgba(0, 0, 0, 0.6);
  --color-light-gray: #1a2a6199;


  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;

  --transition: all 400ms ease;

}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display:none;
}

body {
  font-family: 'Raleway', sans-serif;
  background: var(--color-bg);
  color: var(--color-gray);
  line-height: 1.7;
  background-image: url(../src/assets/IMG_4992.JPG);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

}

/*--------STYLES---------*/
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}

.max-width {
  max-width: 55rem;
}

.max-height {
  max-height: 40rem;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

h1 {
   font-size: 2.5rem;
   color: var(--color-primary);

}

section {
  margin-top: 8rem;

}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
} 
section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}


.text-light-gray {
  color: var(--color-light-gray);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
   color: var(--color-white);
}

.btn {
  width: max-content; 
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
   background: var(--color-light);
   color: var(--color-bg);
   border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ---- Media Queries (Medium Devices) ----- */
@media screen and (max-width: 1024px) {
  .container {
     width: var(--container-width-md);
  }

  .section {
     margin-top: 6rem;
  }
}

/* ---- Media Queries (Small Devices) ----- */
@media screen and (max-width: 600px) {
  .container {
     width: var(--container-width-sm);
  }

  .section > h2 {
     margin-bottom: 2rem;
  }
}

header .container {
    height: 100vh;
    padding-top:7rem;
    overflow: hidden;
}

.container {
    background-color: white;
    opacity: 95%;
    margin-top: 10%;
    margin-bottom: 30px;
    padding: 1rem;
    box-sizing: border-box;
    filter: drop-shadow(0 0 0.6rem var(--color-gray));
    border-radius: 0.8rem;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

hr.divider {
    margin-top: 30px;
    max-width: 40rem;
}

/* ----Avatar ----*/
.avatar {
    margin: auto;
    text-align: center;
    display: block;    
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    opacity: 90%;
    padding: 1rem;
    filter: drop-shadow(0 0 0.2rem var(--color-light-gray));

}

/* ----- Header Links ---- */
.header-links {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    margin-bottom: .5rem;
}

.contact-icon {
    margin-right: .2rem;
    font-size: 1.4rem;
    padding-top: .1rem;
}

/* ----- Header Socials ---- */
.header-socials {
    display: inline-flex;
    gap: 0.8rem;
    padding: 1rem;
}

.scroll__down {
    display: none;
    flex-direction: column;
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/* ---- Media Queries (Medium Devices) ----- */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
    
}

/* ---- Media Queries (Small Devices) ----- */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    
    .scroll__down {
        display: none;
    }
}